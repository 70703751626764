<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-user"></i>
          الملف الشخصي
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 text-center">
          <img
            :src="image ? image : require('@/assets/images/avatars/avatar.png')"
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              object-fit: cover;
              border-radius: 50%;
            "
          />
          <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
          </div>
          <br />
          <input type="file" title="اضغط لتغيير الصورة" id="ingredient_file" />
        </div>
        <div class="form-group">
          <label for="">الاسم</label>
          <input type="text" class="form-control" v-model="name" />
        </div>
        <b-form-checkbox
          v-model="changePassword"
          name="check-button"
          class="custom-control-success"
          switch
          inline
        >
          <h5>
            <i class="fa fa-key"></i>
            تغيير كلمة المرور
          </h5>
        </b-form-checkbox>
        <div class="col-12" v-if="changePassword">
          <div class="g">
            <div class="border g">
              <div class="form-group">
                <label for="">كلمة المرور الحالية</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.old"
                />
              </div>
              <div class="form-group">
                <label for="">كلمة المرور الجديدة</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.new"
                />
              </div>
              <div class="form-group">
                <label for="">تأكيد كلمة المرور الجديدة</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.confirm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 g text-center">
        <button class="btn btn-success" @click="save()">
          <i class="fa fa-save"></i>
          حفظ الاعدادات
        </button>
      </div>
    </div>
    <br>
    <div class="card card-body">
      <div v-if="!user.reseller_code">
        <button class="btn btn-secondary" @click="enableReseller()">
          <i class="fa fa-share"></i>
          تفعيل نظام المسوقين
        </button>
      </div>
      <div v-if="user.reseller_code">
        <div class="form-group">
          <label for="">رابط المشاركة</label>
          <input type="text"
            class="form-control" :value="'https://tahdirbus.tahdir.net/ref/' + user.reseller_code" readonly>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="card card-body border text-center">
              <h4>
                {{ fromme }}
              </h4>
              المسجلين عن طريقك
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card card-body border text-center">
              <h4>
                0
              </h4>
              الرصيد الحالي
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="card card-body border text-center">
              <h4>
                0
              </h4>
              الرصيد المسحوب
            </div>
          </div>
          <div class="col-12 table-responsive">
            <p>سجل عمليات سحب الرصيد</p>
            <table class="table table-hover table-bordered">
              <thead>
                <th>
                  التاريخ
                </th>
                <th>
                  المبلغ
                </th>
                <th>
                  الحالة
                </th>
                <th>
                  التفاصيل
                </th>
              </thead>
            </table>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-success">
              سحب الرصيد الآن
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      fromme: "0",
      password: {
        old: "",
        new: "",
        confirm: "",
      },
      image: "",
      changePassword: false,
      name: "",
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (this.user.admin_id) {
      alert("هذه الصفحة لحساب المدرسة الرئيسي فقط وليس للمشرفين");
      this.$router.push("/");
    }
    if (!checkPer("settings")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    g.name = this.user.name;
    g.image = this.user.image ?? "";
    $.post(api + "/user/general/general-settings", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.accept_all_devices = r.response.accept_all_devices;
      })
      .catch(function () {
        alert("حدث خطا في الاتصال");
      });
      
    $.post(api + "/user/general/fromme", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.fromme = r.response;
      })
      .catch(function () {
        alert("حدث خطا في الاتصال");
      });
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", g.user.jwt);
      $.ajax({
        type: "POST",
        url: api + "/user/general/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    enableReseller(){
      var g = this;
      if(confirm("متأكد من تفعيل نظام المسوقين؟")){
        $.post(api + "/user/general/enable-reseller", {
        jwt: g.user.jwt
      })
        .then(function (r) {
          location.reload()
        })
        .catch(function () {
          alert("حدث خطا في الاتصال");
        });
      }
    },
    save() {
      var g = this;
      $.post(api + "/user/general/profile", {
        jwt: g.user.jwt,
        name: g.name,
        image: g.image,
        changePassword: g.changePassword,
        password: g.password,
      })
        .then(function (r) {
          if (r.status == 100) {
            alert("تم الحفظ");
          } else {
            alert(r.response);
          }
        })
        .catch(function () {
          alert("حدث خطا في الاتصال");
        });
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>